import * as React from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404: Not found" />
      <div className="container pt-16 md:pt-20 lg:pt-40 pb-40">
        <div className="flex justify-center text-center">
          <div>
            <h1 className="h1 color--primary-dark mb-4">404</h1>
            <p className="color--grey-5">Page not found!</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
